import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import BlogStyles from "./Blog.module.scss";
import Tabs from "../components/Tab/Tabs";
import BlogPostCard from "../components/BlogPostCard";
import CONSTANTS from "../constants";
import ContactUsForm from "../components/ContactUs/ContactUsForm";
import Modal from "react-modal";
import { Helmet } from "react-helmet";
import BlogBanner from "../components/BlogSection/FeaturedBlogs";
import FeaturedBlog from "../components/BlogSection/FeaturedBlogs";
import FeaturedBlogimg from "../components/BlogSection/FeaturedBlogimg";

const BlogPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
  location
}) => {
  const url = location.href ? location.href : '';

  const posts = edges.filter((edge) =>
    edge.node.fields.slug.includes(CONSTANTS.BLOG_PATH)
  );

  // view more blogs function
const [isViewMoreClick , setIsViewMoreClick]=React.useState([])
const[disableViewMoreClick,setdisableViewMoreClick]=React.useState(false)
const intial_post_display=posts.slice(0,9); 
React.useEffect(()=>{
    setIsViewMoreClick(intial_post_display)
},[])
  
const displayallpost=()=>{
  setIsViewMoreClick(posts)
  document.getElementById('all').style.display = 'none';
  setdisableViewMoreClick(true)
    } 


  const technologyPosts = posts.filter(
    (post) =>
      post.node.frontmatter.category === CONSTANTS.BLOG_CATEGORIES.technology
  );

const [isViewMoreClickTech , setIsViewMoreClickTech]=React.useState([])
const[disableViewMoreClickTech,setdisableViewMoreClickTech]=React.useState(false)
const intial_tech_post_display=technologyPosts.slice(0,9); 
React.useEffect(()=>{
    setIsViewMoreClickTech(intial_tech_post_display)
},[])
const displaytechnologypost=()=>{
    setIsViewMoreClickTech(technologyPosts)
    document.getElementById('tech').style.display = 'none';
    setdisableViewMoreClickTech(true)
    } 
  const businessPosts = posts.filter(
    (post) =>
      post.node.frontmatter.category === CONSTANTS.BLOG_CATEGORIES.business
  );

  const artificialIntelligencePosts = posts.filter(
    (post) =>
      post.node.frontmatter.category ===
      CONSTANTS.BLOG_CATEGORIES.artificialIntelligence
  ); 

  let allcount =posts.length;
  
  


  // const sampleCategoryPosts = posts.filter(
  //   (post) =>
  //     post.node.frontmatter.category ===
  //     CONSTANTS.BLOG_CATEGORIES.sampleCategory
  // );
  const featuredblogs = posts.filter(
    (post) =>
      post.node.frontmatter.isFeatured 
      
  );
  const featuredblogsimg = posts.filter(
    (post) =>
      post.node.frontmatter.title ===CONSTANTS.BLOG_FEATURED.title
      
  );
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }
  
    const scrollTop = () => {
      window.scroll(0, 1);
    };

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Blogs on Adobe Experience Manager by Adobe Certified Architects
        </title>
        <meta
          name="description"
          content="Keep yourself up to date on industry best practices and trends on creating incredible digital experiences using Adobe Experience Manager (AEM) and Salesforce Communities."
        ></meta>
      </Helmet>
  <section className={BlogStyles.section_gray}>
    <div className={BlogStyles.gray_bg}>
        <h2 >Featured articles</h2> 
        {/* <FeaturedBlog  post={featuredblogs[0]} /> */}
      <div className={BlogStyles.featuredblog}>  
        <div>  
            <div title="Featured">
              <div className={BlogStyles.cardWrapper}>
                {featuredblogsimg.map((post) => {
                  const {
                    node: { id },
                  } = post;
                  return <FeaturedBlogimg key={id} post={post} />;
                })}
              </div>
            </div>
        </div>
            <div>
            <div title="Featured">
              <div className={BlogStyles.featuredcardWrapper}>
                {featuredblogs.map((post) => {
                  const {
                    node: { id },
                  } = post;
                  return <FeaturedBlog key={id} post={post} />;
                })}
              </div>
            </div>
            <div className={BlogStyles.border_bottom}></div>
          </div>
      </div>
    </div></section>   
  <div className={BlogStyles.container}>
        <div className={BlogStyles.title}>
          <h2>All Articles ({allcount})</h2>
        </div>
        <div className="center-align">
          <Tabs >   
            <div title="All" >
              <div className={BlogStyles.cardWrapper}>
                {isViewMoreClick?.map((post) => {
                  const {
                    node: { id },
                  } = post;
                  return <BlogPostCard key={id} post={post} />;
                })}
              </div>
              <div className={BlogStyles.viewmore_button_all}><button id="all" onClick={displayallpost}>View more</button></div>
            </div>
            
            <div title="Technology">
              <div className={BlogStyles.cardWrapper}>
                {isViewMoreClickTech?.map((post) => {
                  const {
                    node: { id },
                  } = post;
                  return <BlogPostCard key={id} post={post} />;
                })}
              </div>
              <p className={BlogStyles.viewmore_button_tech} id="tech" onClick={displaytechnologypost} >View more</p>
              {/* <div className={BlogStyles.viewmore_button_tech}><button id="tech" onClick={displaytechnologypost}>View more</button></div> */}
            </div>
            <div title="Business">
              <div className={BlogStyles.cardWrapper}>
                {businessPosts.map((post) => {
                  const {
                    node: { id },
                  } = post;
                  return <BlogPostCard key={id} post={post} />;
                })}
              </div>
            </div>
            <div title="AI" > 
              <div className={BlogStyles.cardWrapper}>
                {artificialIntelligencePosts.map((post) => {
                  const {
                    node: { id },
                  } = post;
                  return <BlogPostCard key={id} post={post} />;
                })}
              </div>
            </div>
            {/*<div title="Sample Category">
              <div className={BlogStyles.cardWrapper}>
                {sampleCategoryPosts.map((post) => {
                  const {
                    node: { id },
                  } = post;
                  return <BlogPostCard key={id} post={post} />;
                })}
              </div>
              </div>*/}
          </Tabs>
          
        </div>
  </div>
      <div className={BlogStyles.connectSection}>
        <div className={`${BlogStyles.learnMoreSection}`}>
          <div className={`${BlogStyles.learnMoreContents}`}>
            <h4>Would you like to learn more about our services?</h4>
            <p onClick={openModal}>
              Let's connect
              <span>
                <img src="../../RedVector.svg"></img>
              </span>
            </p>
          </div>
        </div>
        <div className={BlogStyles.gotoTop}>
          <div className={`${BlogStyles.referenceButton}`}>
            <a href="#header" onClick={scrollTop}>
              <img src="../../Top.svg"></img> Go to Top
            </a>
          </div>
        </div>
      </div>
      <div>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          // style={customStyles}
          contentLabel="Example Modal"
          className={BlogStyles.modalOpen}
          overlayClassName={BlogStyles.modalOverlay}
        >
          <div className={BlogStyles.modalHeader}>
            <img onClick={closeModal} src="../../Close.svg"></img>
            <img onClick={closeModal} src="../../CloseMobile.svg"></img>
          </div>
          <div className={BlogStyles.modalBody}>
            <div className={BlogStyles.modalTitle}>
              <h2>Let’s Connect</h2>
              <p>
                Fill out this short form, and we’ll get back to you within one
                working day.
              </p>
            </div>
            <div className={BlogStyles.modalForm}>
              {/*<form>
                <div className={HomeStyles.infoFields}>
                  <input type="text" placeholder="Name*"></input>
                  <input type="text" placeholder="Email*"></input>
                </div>
                <div className={HomeStyles.reasonField}>
                  <label for="help">How can we help?</label>
                  <textarea id="help" name="help" rows="4"></textarea>
                </div>
                <div className={HomeStyles.modalFooter}>
                  <button id="submit">submit</button>
                </div>
              </form>*/}
              <ContactUsForm  url={url}  page="Blogs" section="Let's Connect"/>
            </div>
          </div>
        </Modal>
      </div>
    </Layout>
  );
};

export default BlogPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          fields {
            slug
          }
          id
          excerpt(pruneLength: 250)
          frontmatter {
            
            date(formatString: "DD MMMM  YYYY")
            title
            category
            author
            isFeatured
            description
          
          }
        }
      }
    }
  }
`;
