import React from "react";
import TabStyles from "./Tabs.module.scss";
import styled from "styled-components";

const TabLink = styled.button`
  
  padding: 12px;
  border: 0 none;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  color: ${(p) => (p.selected ? "#FFFFFF" : "#555555")};
  background: ${(p) => (p.selected ? "#ED0600" : "transparent")};
  &:focus {
  outline: 0 none;
  }
`;

class Tabs extends React.Component {
  state = { selected: 0 };
  select = (i) => this.setState({ selected: i });
  render() {
    const { children } = this.props;
    const tabs = children.map((c, i) => (
      <TabLink
        key={i}
        selected={i === this.state.selected}
        onClick={this.select.bind(null, i)}
      >
        {c.props.title}
      </TabLink>
    ));

    return (
      <div>
        <div className={TabStyles.tabContainer}>
          <div className={TabStyles.tabWrapper}>{tabs}</div>
        </div>
        <div>{children[this.state.selected]}</div>
      </div>
    );
  }
}

export default Tabs;

