import React from 'react'
import { Link } from "gatsby";
import CONSTANTS from '../../constants';
// import BlogPostCardStyles from '../BlogPostCard/BlogPostCard.module.scss';
import BlogPostCardStyles from './FeaturedBlogimg.module.scss';

function FeaturedBlogs({ post }) {
    const { node: { frontmatter: { isFeatured, author,title, category, date }, fields: { slug } } } = post;
    const blogImagePath = slug.replace(CONSTANTS.BLOG_PATH, '');

    

        return (
            <Link to={slug}>
            <div className={BlogPostCardStyles.card} key={post.node.id}>
                <div className={BlogPostCardStyles.cardImageWrapper}>
                    <img src={`../../${blogImagePath}.png`}></img>
                </div>
                <div className={BlogPostCardStyles.image_author}> 
                    <p className=''> 
                            <span><img  className={BlogPostCardStyles.authorImage} src={`../../blogs/authors/${author}.png`}></img></span>    <span className={BlogPostCardStyles.span_2}> {author}</span>
                    </p>
                </div>
                <div className={BlogPostCardStyles.cardTitle}>
                    <p>{title}</p>
                </div>
                <div className={BlogPostCardStyles.author_time}>
                        <p>
                            <span><span> {date}</span></span> <span className={BlogPostCardStyles.span_2}>
                                {/* <img src='../../blogs/authors/book-open.png'></img> */}
                            {/* <span> 5 min read</span> */}
                            </span>
                        </p>
                </div>
            </div>
        </Link>
            
        )
}

export default FeaturedBlogs

